<template>
    <el-card shadow="never" class="aui-card--fill">
        <el-table :data="dataList" border style="width: 100%">
            <el-table-column
                prop="id"
                :label="$t('online.userId')"
                header-align="center"
                align="center"
            ></el-table-column>
            <el-table-column
                prop="username"
                :label="$t('online.username')"
                header-align="center"
                align="center"
            ></el-table-column>
            <el-table-column
                prop="realName"
                :label="$t('online.realName')"
                header-align="center"
                align="center"
            ></el-table-column>
            <el-table-column
                prop="mobile"
                :label="$t('sms.mobile')"
                header-align="center"
                align="center"
            ></el-table-column>
        </el-table>
    </el-card>
</template>

<script>
export default {
    data() {
        return {
            dataList: [],
        };
    },
    created() {
        this.query();
    },
    methods: {
        async query() {
            const data = await this.$http.get('websocket/backend');
            const { data: dataList, code, msg } = data.data;
            if (code) {
                this.$notify.error({
                    title: '失败',
                    message: msg,
                });
                return;
            }
            this.dataList = dataList;
        },
    },
};
</script>

<style></style>
